<template><div /></template>

<script setup>
import { onMounted, computed, watch } from "vue";
import { useRoute } from "#vue-router";
import { useSearchStore } from "~/store/search.ts";
import { useSearchFiltersStore } from "~/store/searchFilter";
import { useChartsStore } from "~/store/charts.ts";

const chartStore = useChartsStore();
const savedSearchStore = useSearchStore();
const searchFiltersStore = useSearchFiltersStore();
const $route = useRoute();

const route = computed(() => {
  return $route.name;
});

watch(
  () => route.value,
  (newVal) => {
    savedSearchStore.currentRoute = newVal;
  },
);

onMounted(async () => {
  savedSearchStore.currentRoute = route.value;
  await savedSearchStore.fetchSearchFilters();
  searchFiltersStore.setCurrentSearchFilter(null, false);

  await chartStore.fetchOrgTemplates();
});
</script>
